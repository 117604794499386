<template>
    <div id="settings" class="settings-wrapper py-4 px-3">
        <div class="mb-4 d-flex align-center">
            <v-btn icon @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-spacer></v-spacer>
            <p class="mb-0 text-center font-weight-medium text-h6">{{$t("homeItemSettingsTitle")}}</p>
            <v-spacer></v-spacer>
        </div>
        <v-divider class="mx-3 my-3"></v-divider>
        <v-expansion-panels v-model="panel" multiple readonly>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <p class="mb-0 primary--text font-weight-bold text-h6">{{$t('settingsCustomerPayment')}}</p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="payment-methods-list-selection">
                        <p class="mb-0 text-subtitle-2 font-weight-bold">Select payment methods to show :</p>
                        <v-switch v-for="paymentMethod in paymentMethods" :key="`payementMethod-${paymentMethod.id}`" color="primary" v-model="customerPayment.selectedPaymentMethods" :label="$t(paymentMethod.label)" :value="paymentMethod.value" @change="handlePaymentSelect($event)" multiple hide-details inset dense></v-switch>
                    </div>
                    
                    <div class="mt-6 payment-methods-list-selection">
                        <p class="mb-0 text-subtitle-2 font-weight-bold">Select features to show :</p>
                        <v-switch v-model="customerPayment.coupons" :label="$t('settingsCustomerPaymentCoupons')" hide-details inset dense></v-switch>
                        <v-switch v-model="customerPayment.crossSell" :label="$t('settingsCustomerPaymentCrossSell')" hide-details inset dense></v-switch>
                        <v-switch v-model="customerPayment.shipping" :label="$t('settingsCustomerPaymentShipping')" hide-details inset dense></v-switch>
                    </div>
                    
                    <div class="mt-6 payment-methods-list-selection">
                        <p class="mb-0 text-subtitle-2 font-weight-bold">Personal information to retreive :</p>
                        <v-switch v-model="customerPayment.firstName" :label="$t('settingsCustomerPaymentFirstName')" hide-details inset dense></v-switch>
                        <v-switch v-model="customerPayment.lastName" :label="$t('settingsCustomerPaymentLastName')" hide-details inset dense></v-switch>
                        <v-switch v-model="customerPayment.phoneNumber" :label="$t('settingsCustomerPaymentPhoneNumber')" hide-details inset dense></v-switch>
                        <v-switch v-model="customerPayment.email" :label="$t('settingsCustomerPaymentEmail')" hide-details inset dense></v-switch>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

    </div>
</template>

<script>
    import {pageInfoMixin} from "@/mixins/pageInfoMixin"

    export default {
        mixins: [pageInfoMixin],
        name: 'Settings',
        data() {
            return {
                panel: [0],
                customerPayment: {
                    coupons: false,
                    crossSell: false,
                    firstName: false,
                    lastName: false,
                    phoneNumber: false,
                    shipping: false,
                    email: false,
                    selectedPaymentMethods: ["SIPS", "osWallet", "BNPL"]
                },
                paymentMethods: [
                    {
                        id: 1,
                        value: "osWallet",
                        label: "settingsOsWallet"
                    },
                    {
                        id: 2,
                        value: "SIPS",
                        label: "settingsSIPS"
                    },
                    {
                        id: 3,
                        value: "BNPL",
                        label: "settingsBNPL"
                    }
                ]
            }
        },
        created() {
            let settings = localStorage.getItem("AugmentedSeller_settings")
            if(settings){
                this.customerPayment = JSON.parse(settings)
            }
        },
        methods: {
            handlePaymentSelect(event){
                console.log(event);
                event.preventDefault()
            }
        },
        watch: {
            customerPayment: {
                handler(){
                    localStorage.setItem("AugmentedSeller_settings", JSON.stringify(this.customerPayment))
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

</style>
